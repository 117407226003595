import { Course } from 'facades/CourseFacade';
import { Video } from 'facades/VideoFacade';

export const SET_PLAYER_CURRENT_COURSE = '[PLAYER] SET CURRENT COURSE';
export const PATCH_PLAYER_CURRENT_COURSE = '[PLAYER] PATCH CURRENT COURSE';
export const SET_PLAYER_CURRENT_VIDEO = '[PLAYER] SET CURRENT VIDEO';
export const REFRESH_PLAYER = '[PLAYER] REFRESH';

export function setPlayerCurrentCourse(course: Course | null) {
  return {
    type: SET_PLAYER_CURRENT_COURSE,
    payload: course,
  };
}

export function patchPlayerCurrentCourse(course: Course | null) {
  return {
    type: PATCH_PLAYER_CURRENT_COURSE,
    payload: course,
  };
}

export function refreshPlayer() {
  return {
    type: REFRESH_PLAYER,
  };
}

export function setPlayerCurrentVideo(video: Video | null) {
  return {
    type: SET_PLAYER_CURRENT_VIDEO,
    payload: video,
  };
}
