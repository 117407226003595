import { RouteConfig } from 'configs';
import { MemberRole } from 'facades/PermissionFacade';
import React from 'react';

const TalkToUsConfig: RouteConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
      },
    },
  },
  auth: [MemberRole],
  routes: [
    {
      path: '/talk-to-us',
      component: React.lazy(() => import('./TalkToUs')),
      exact: true,
    },
  ],
};

export default TalkToUsConfig;
