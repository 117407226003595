import React from 'react';
import jssExtend from 'jss-plugin-extend';
import { create } from 'jss';
import { StylesProvider, jssPreset, createGenerateClassName } from '@material-ui/styles';
import FuseTheme from 'components/FuseTheme';
import { Provider } from 'react-redux';
import store from './store';
import history from '@history';
import { Router } from 'react-router-dom';
import FuseLayout from 'components/FuseLayout';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import Authorization from './components/Authorization';
import { RouteProvider } from './contexts/RouteContext';
import './services/firebase';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point')!,
});

const generateClassName = createGenerateClassName();

const App = () => (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <RouteProvider>
        <AuthenticationProvider>
          <Router history={history}>
            <Authorization>
              <FuseTheme>
                <FuseLayout />
              </FuseTheme>
            </Authorization>
          </Router>
        </AuthenticationProvider>
      </RouteProvider>
    </Provider>
  </StylesProvider>
);

export default App;
