import React from 'react';
import { Hidden, makeStyles, Theme } from '@material-ui/core';
import UserMenu from 'layouts/layout1/components/UserMenu';
import { Link } from 'react-router-dom';
import NavbarMobileToggleButton from './NavbarMobileToggleButton';

const useStyles = makeStyles((theme: Theme) => ({
  logoWrapper: {
    height: 48,
    margin: theme.spacing(0, 2),
    '& img': {
      height: '100%',
    },
  },
  pages: {
    margin: theme.spacing(0, 2),
    flexGrow: 1,
    display: 'flex',
    '& a': {
      margin: theme.spacing(0, 2),
      ...theme.typography.body2,
      '& button': {
        textTransform: 'none',
      },
    },
  },
}));

function ToolbarContent() {
  const styles = useStyles();
  return (
    <>
      <Hidden mdUp>
        <NavbarMobileToggleButton className="w-48 h-48 p-0" />
      </Hidden>

      <Hidden smDown>
        <Link to="/" className={styles.logoWrapper}>
          <img src="/assets/imgs/logo.png" alt="logo" />
        </Link>
      </Hidden>

      <Hidden smDown>
        <div className={styles.pages}>
          <Link to={'/courses'}>Início</Link>
          <Link to={'/training'}>Treinamentos</Link>
          <Link to={'/nutrition'}>Nutricionais</Link>
        </div>
      </Hidden>

      <div className="flex-1" />

      <UserMenu />
    </>
  );
}

export default ToolbarContent;
