import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { useDispatch } from 'react-redux';
import * as Actions from 'store/actions';
import _ from '@lodash';
import { Theme } from '@material-ui/core';
import { useTypedSelector } from 'store';
import Layout from 'layouts/layout1/Layout';
import { useRouteState } from 'contexts/RouteContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}));

function FuseLayout(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const defaultSettings = useTypedSelector(({ fuse }) => fuse.settings.defaults);
  const settings = useTypedSelector(({ fuse }) => fuse.settings.current);

  const classes = useStyles();
  const routes = useRouteState();

  React.useEffect(() => {
    function routeSettingsCheck() {
      let routeSettings = _.merge({}, defaultSettings);
      let matched = matchRoutes(routes, props.location.pathname)[0];

      if (matched?.route?.settings) {
        routeSettings = _.merge(routeSettings, matched.route.settings);
      }

      if (matched?.route?.routes) {
        const submatched = matchRoutes(matched.route.routes, props.location.pathname)[0];

        if (submatched?.route?.settings) {
          routeSettings = _.merge(routeSettings, submatched.route.settings);
        }
      }

      if (matched?.route?.settings) {
        if (!_.isEqual(settings, routeSettings)) {
          dispatch(Actions.setSettings(_.merge({}, routeSettings)));
        }
      } else {
        if (!_.isEqual(settings, defaultSettings)) {
          dispatch(Actions.resetSettings());
        }
      }
    }

    routeSettingsCheck();
  }, [defaultSettings, dispatch, props.location.pathname, routes, settings]);

  return <Layout classes={{ root: classes.root }} />;
}

export default withRouter(React.memo(FuseLayout));
