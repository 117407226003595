import 'fontsource-roboto/300-normal.css';
import 'fontsource-roboto/400-normal.css';
import 'fontsource-roboto/500-normal.css';
import 'fontsource-roboto/700-normal.css';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
