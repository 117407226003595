import { Reducer } from 'redux';
import { MyAction } from 'store';
import * as Actions from '../../actions/fuse/index';

interface MessageState {
  state: boolean;
  options: {
    anchorOrigin?: {
      vertical: 'bottom' | 'top';
      horizontal: 'left' | 'center' | 'right';
    };
    autoHideDuration?: number | null;
    message: string;
    variant?: 'success' | 'warning' | 'error' | 'info';
    action?: React.ReactNode;
  };
}

const initialState: MessageState = {
  state: false,
  options: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    autoHideDuration: 6000,
    message: 'Hi',
    variant: 'success',
  },
};

const message: Reducer<MessageState, MyAction> = function (
  state = initialState,
  action: any
): MessageState {
  switch (action.type) {
    case Actions.SHOW_MESSAGE: {
      return {
        state: true,
        options: {
          ...initialState.options,
          ...action.options,
        },
      };
    }
    case Actions.HIDE_MESSAGE: {
      return {
        ...state,
        state: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default message;
