const environment = {
  lang: 'pt-BR',
  title: 'Fight Home',
  slug: 'fighthome',
  membersURL: 'http://members-fighthome.web.app/',
  endPoint: 'https://us-central1-fighthome.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyD-0CFgIi8kwBc41dEXZ1DQThGNJRSVykM',
    authDomain: 'fighthome.firebaseapp.com',
    projectId: 'fighthome',
    storageBucket: 'fighthome.appspot.com',
    messagingSenderId: '523640412955',
    appId: '1:523640412955:web:1834bb6b87945e0f5851be',
    measurementId: 'G-M9EDHDX6ZJ',
  },
  csEmail: 'contatofighthome@gmail.com',
  csTelegram: null,
  csWhatsapp: '48 9125‑9373',
  copyright: 'Fight Home Treinamentos LTDA © Copyright - Todos os direitos reservados',
  address: 'Fight Home Treinamentos LTDA',
};

export default environment;
