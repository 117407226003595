import React from 'react';
import { Slide, useScrollTrigger } from '@material-ui/core';

const HideOnScroll: React.FunctionComponent<{ scrollbarRef?: any }> = ({
  scrollbarRef,
  children,
}) => {
  const [, setCount] = React.useState(0);
  const trigger = useScrollTrigger({ target: scrollbarRef?.current || undefined });

  React.useEffect(() => {
    const timer = setTimeout(() => setCount(1), 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children as any}
    </Slide>
  );
};

export default HideOnScroll;
