import { docData } from '../utils/rxfire';
import { map, switchMap, tap } from 'rxjs/operators';
import { auth, firestore } from 'services';
import { authState, idToken, user } from 'rxfire/auth';
import { combineLatest, of } from 'rxjs';
import environment from 'environment';
import { parsePermissions } from './PermissionFacade';
import axios from 'axios';
import UserLoginFacade from './UserLoginFacade';

export interface User {
  id: string;

  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  language: 'pt-BR' | 'pt-PT' | 'es' | 'en-US';

  avatarURL?: string | null;
}

interface SignupResponse {
  success: boolean;
  data: { customToken: string };
  error_code: string;
  error_message?: string;
}

export class AuthError extends Error {
  constructor(public message: string, public fields?: Record<string, string | undefined>, public code?: string) {
    super(message);
    this.name = 'AuthError';
  }
}

const emailErrorCodesMap = {
  'auth/email-already-in-use': 'Já existe uma conta com esse endereço de email.',
  'auth/invalid-email': 'Email inválido.',
  'auth/user-not-found': 'Email inválido. Por favor confira e informe o email corretamente.',
  'auth/user-disabled': 'Usuário desativado.',
};

const signupEmailErrorCodesMap = {
  'auth/email-already-in-use': 'Esse cadastro já está atualizado!.',
  'auth/user-not-found': 'Email inválido. Por favor confira e informe o mesmo email da compra.',
};

const passwordErrorCodesMap = {
  'auth/weak-password': 'Senha fraca.',
  'auth/wrong-password': 'Senha errada.',
  'auth/invalid-password': 'A senha deve ter pelo menos 6 caracteres.',
};

const otherErrorCodesMap = {
  'auth/network-request-failed': 'Aconteceu uma falha de rede, verifique sua conexão com a internet e tente novamente.',
  'auth/invalid-api-key': 'Aconteceu uma falha interna na API.',
};

const REGISTER_API = `${environment.endPoint}/register`;
const INVITATION_API = `${environment.endPoint}/invitation`;

class AuthFacade {
  private collection = firestore.collection('users');

  public docRef(id: string) {
    return this.collection.doc(id);
  }

  public getUser$() {
    let prev: any;
    return user(auth).pipe(
      switchMap(user => {
        if (prev === null && user) {
          UserLoginFacade.create(user.uid);
        }
        prev = user;
        return user ? docData<User>(this.docRef(user.uid), 'id') : of(null);
      })
    );
  }

  public getPermissions$() {
    return combineLatest([authState(auth), this.getUser$()]).pipe(
      switchMap(([userRecord, user]) => {
        if (!user || !userRecord) {
          return of(null);
        }
        return userRecord.getIdTokenResult().then(id => parsePermissions(id.claims.permissions));
      })
    );
  }

  public async submitLogin(fields: Record<'email' | 'password', string>) {
    try {
      await auth.signInWithEmailAndPassword(fields.email, fields.password);
    } catch (error: any) {
      throw new AuthError(otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap], {
        email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
        password: passwordErrorCodesMap[error.code as keyof typeof passwordErrorCodesMap],
      });
    }
  }

  public async sendPasswordResetEmail(email: string) {
    try {
      auth.languageCode = environment.lang;
      await auth.sendPasswordResetEmail(email.trim());

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        error: {
          email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
          other: otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap],
        },
      };
    }
  }

  public async submitSignup(fields: Record<'email' | 'password', string>) {
    const res = await axios.post<SignupResponse>(REGISTER_API, fields);

    if (!res.data.success) {
      const emailMap = { ...emailErrorCodesMap, ...signupEmailErrorCodesMap };
      const message = otherErrorCodesMap[res.data.error_code as keyof typeof otherErrorCodesMap];
      const email = emailMap[res.data.error_code as keyof typeof emailMap];
      const password = passwordErrorCodesMap[res.data.error_code as keyof typeof passwordErrorCodesMap];

      if (!message && !email && !password) {
        throw new AuthError(`Desculpe, aconteceu uma falha inesperada!`);
      }

      throw new AuthError(message, { email, password }, res.data.error_code);
    }

    return res.data.data;
  }

  public async signupAndLogin(fields: Record<'email' | 'password', string>) {
    const response = await this.submitSignup(fields);

    await this.loginWithCustomToken(response.customToken);

    return response;
  }

  public async loginWithCustomToken(customToken: string) {
    try {
      await auth.signInWithCustomToken(customToken);
    } catch (error) {
      throw new AuthError(otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap]);
    }
  }

  public async loginWithEmailAndPassword(email: string, password: string) {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error: any) {
      throw new AuthError(otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap], {
        email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
        password: passwordErrorCodesMap[error.code as keyof typeof passwordErrorCodesMap],
      });
    }
  }

  public async invite(fields: Record<'firstName' | 'email' | 'phoneNumber' | 'invitationId', string>) {
    const res = await axios.post(INVITATION_API, fields);

    if (!res.data.success) {
      const message = otherErrorCodesMap[res.data.error_code as keyof typeof otherErrorCodesMap];
      const email = emailErrorCodesMap[res.data.error_code as keyof typeof emailErrorCodesMap];

      if (!message && !email) {
        throw new AuthError(`Desculpe, aconteceu uma falha inesperada!`);
      }

      throw new AuthError(message, { email });
    }

    return res.data.data;
  }

  public async inviteAndLogin(fields: Record<'firstName' | 'email' | 'phoneNumber' | 'invitationId', string>) {
    const response = await this.invite(fields);

    if (response.customToken) {
      await this.loginWithCustomToken(response.customToken);
    }

    return response.customToken;
  }
}

const instance = new AuthFacade();

export default instance;
