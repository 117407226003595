const config = {
  title: 'Layout 1 - Vertical',
  defaults: {
    mode: 'fullwidth',
    scroll: 'content',
    navbar: {
      display: true,
      folded: true,
      position: 'left',
    },
    toolbar: {
      display: false,
      style: 'static',
      position: 'below',
      color: 'primary' as
        | 'default'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'transparent'
        | undefined,
      elevation: 0,
    },
    navigationBar: {
      display: false,
      position: 'below',
    },
    footer: {
      display: false,
      position: 'below',
      style: 'not-fixed',
    },
    leftSidePanel: {
      display: false,
    },
    rightSidePanel: {
      display: false,
    },
  },
};

export default config;
