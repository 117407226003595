import React from 'react';
import { RouteConfig } from 'configs';
import { MemberRole } from 'facades/PermissionFacade';

const CoursesTrainingConfig: RouteConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: true,
          color: 'secondary',
          elevation: 0,
        },
        footer: {
          display: true,
        },
      },
    },
    theme: {
      toolbar: 'defaultToolbar',
    },
  },
  auth: [MemberRole],
  routes: [
    {
      path: '/training',
      component: React.lazy(() => import('./CoursesTraining')),
      exact: true,
    },
  ],
};

export default CoursesTrainingConfig;
