import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import _ from '@lodash';
import FuseThemesConfig from './themesConfig';

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
  typography: {
    fontFamily: ['Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
};

export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.6rem',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        lineHeight: '1.5',
      },
    },
  },
};

export function mainThemeVariations(theme: typeof FuseThemesConfig[keyof typeof FuseThemesConfig]) {
  return {
    mainThemeDark: createMuiTheme(
      _.merge({}, defaultThemeOptions, theme, {
        palette: { type: 'dark' },
        ...mustHaveThemeOptions,
      }) as ThemeOptions
    ),
    mainThemeLight: createMuiTheme(
      _.merge({}, defaultThemeOptions, theme, {
        palette: { type: 'light' },
        ...mustHaveThemeOptions,
      }) as ThemeOptions
    ),
  };
}
