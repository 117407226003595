import { createStore, applyMiddleware, Action } from 'redux';
import reducer from './reducers';
import thunk, { ThunkAction, ThunkMiddleware, ThunkDispatch as TThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = createStore(reducer, applyMiddleware(thunk as ThunkMiddleware<RootState, MyAction>));

export default store;

export interface MyAction extends Action<string> {
  payload?: any;
}

export type RootState = ReturnType<typeof reducer>;

export type AppThunk = ThunkAction<void, RootState, undefined, MyAction>;

export type ThunkDispatch = TThunkDispatch<RootState, undefined, MyAction>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
