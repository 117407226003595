import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .logo-icon': {
      width: 32,
      height: 32,
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    '& .react-badge, & .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
      overflow: 'hidden',
      textOverflow: 'ellipse',
      whiteSpace: 'nowrap',
    },
  },
  reactBadge: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#61DAFB',
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      {/* <img className="logo-icon" src="/assets/imgs/logo.png" alt="logo" /> */}
      <Typography className="text-16 font-bold logo-text">FIGHT HOME</Typography>
    </div>
  );
}

export default Logo;
