import NavigationFacade, { Navigation } from 'facades/NavigationFacade';
import { AppThunk } from 'store';

export const GET_NAVIGATION = '[NAVIGATION] GET NAVIGATION';
export const SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';
export const RESET_NAVIGATION = '[NAVIGATION] RESET NAVIGATION';

export function getNavigation() {
  return {
    type: GET_NAVIGATION,
  };
}

export function setNavigation(navigation: Navigation[]) {
  return {
    type: SET_NAVIGATION,
    navigation,
  };
}

export function resetNavigation() {
  return {
    type: RESET_NAVIGATION,
  };
}

export function appendNavigationItem(item: Navigation, parentId: string): AppThunk {
  return (dispatch, getState) => {
    const { navigation } = getState().fuse;
    return dispatch({
      type: SET_NAVIGATION,
      payload: NavigationFacade.appendNavItem(navigation, item, parentId),
    });
  };
}

export function prependNavigationItem(item: Navigation, parentId: string): AppThunk {
  return (dispatch, getState) => {
    const { navigation } = getState().fuse;
    return dispatch({
      type: SET_NAVIGATION,
      payload: NavigationFacade.prependNavItem(navigation, item, parentId),
    });
  };
}

export function updateNavigationItem(id: string, item: Navigation): AppThunk {
  return (dispatch, getState) => {
    const { navigation } = getState().fuse;
    return dispatch({
      type: SET_NAVIGATION,
      payload: NavigationFacade.updateNavItem(navigation, id, item),
    });
  };
}

export function removeNavigationItem(id: string): AppThunk {
  return (dispatch, getState) => {
    const { navigation } = getState().fuse;
    return dispatch({
      type: SET_NAVIGATION,
      payload: NavigationFacade.removeNavItem(navigation, id),
    });
  };
}
