import React from 'react';
import { AppBar, ThemeProvider, Toolbar } from '@material-ui/core';
import ToolbarContent from './ToolbarContent';
import HideOnScroll from './HideOnScroll';
import { useTypedSelector } from 'store';

function ToolbarLayout1({ scrollbarRef }: { scrollbarRef?: React.RefObject<HTMLDivElement> }) {
  const settings = useTypedSelector(state => state.fuse.settings.current.layout.config.toolbar);
  const toolbarTheme = useTypedSelector(({ fuse }) => fuse.settings.toolbarTheme);

  return (
    <>
      <ThemeProvider theme={toolbarTheme}>
        <HideOnScroll scrollbarRef={scrollbarRef}>
          <AppBar
            color={
              settings.color as
                | 'default'
                | 'inherit'
                | 'primary'
                | 'secondary'
                | 'transparent'
                | undefined
            }
            elevation={settings.elevation}
          >
            <Toolbar className="">
              <ToolbarContent />
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
      </ThemeProvider>
    </>
  );
}

export default ToolbarLayout1;
