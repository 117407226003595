export interface BreadcrumbItem {
  id: string;
  name?: string;
  slug?: string;
  title: string;
  link?: string;
}

class BreadcrumbFacade {
  public updateBreadcrumbItem(nav: BreadcrumbItem[], id: string, item: BreadcrumbItem) {
    const list: BreadcrumbItem[] = [];

    for (const navItem of nav) {
      if (navItem.id === id) {
        list.push(item);
      } else {
        list.push(navItem);
      }
    }

    return list;
  }

  public removeBreadcrumbItem(nav: BreadcrumbItem[], id: string) {
    return nav.filter(it => it.id !== id);
  }

  public appendBreadcrumbItem(nav: BreadcrumbItem[], item: BreadcrumbItem, parentId?: string) {
    const index = nav.findIndex(it => it.id === parentId);

    if (index !== -1) {
      return [...nav.slice(0, index + 1), item, ...nav.slice(index + 1)];
    } else {
      return [...nav, item];
    }
  }
}

export function breadcrumbItem(id: string, title: string, slug: string): BreadcrumbItem {
  return { id, title, slug };
}

const instance = new BreadcrumbFacade();

export default instance;
