import React from 'react';
import { matchRoutes } from 'react-router-config';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouteState } from 'contexts/RouteContext';
import { useAuthentication, usePermissions } from '../contexts/AuthenticationContext';
import LoadingPage from 'pages/loading/LoadingPage';
import { hasPermission } from 'facades/PermissionFacade';

type Props = { children: React.ReactNode };

function Authorization({ children }: Props) {
  const { pathname, state } = useLocation<any>();
  const history = useHistory();
  const routes = useRouteState();
  const { user, loading: loadingUser } = useAuthentication();
  const { permissions, loading: loadingPermissions } = usePermissions();

  const matched = React.useMemo(() => {
    return loadingUser || loadingPermissions ? false : matchRoutes(routes, pathname)[0];
  }, [loadingUser, permissions, routes, pathname]);

  const accessGranted = React.useMemo(() => {
    return matched ? hasPermission(matched.route.auth, permissions) : false;
  }, [matched, permissions]);

  React.useEffect(() => {
    if (!loadingUser && !loadingPermissions && !accessGranted) {
      const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

      if (!user) {
        if (pathname !== '/entrar') {
          history.push({
            pathname: '/entrar',
            state: { redirectUrl: pathname },
          });
        }
      } else {
        history.push({
          pathname: redirectUrl,
        });
      }
    }
  }, [loadingUser, loadingPermissions, accessGranted, pathname, user, history, state]);

  return <>{loadingUser || loadingPermissions ? <LoadingPage /> : accessGranted ? children : ''}</>;
}

export default React.memo(Authorization);
