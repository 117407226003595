import React from 'react';
import { makeStyles, Theme, Toolbar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useTypedSelector } from 'store';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import environment from 'environment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'between',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    '& li': {
      lineHeight: '1.25',
      padding: theme.spacing(1, 0.5),
      letterSpacing: '0.025em',
    },
    '& li a': {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  },
  copyright: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  logoContainer: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(6),
    width: 128,
  },
}));

function FooterLayout1() {
  const footerTheme = useTypedSelector(({ fuse }) => fuse.settings.footerTheme);

  const styles = useStyles();

  return (
    <ThemeProvider theme={footerTheme}>
      <div className={styles.root}>
        <div className={clsx(styles.logoContainer, 'mx-auto sm:mx-0')}>
          <img src="/assets/imgs/logo.png" alt="logo" />
        </div>
        <ul className={styles.list}>
          <li>
            <Link to="/talk-to-us">FALE COM A GENTE</Link>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://fighthome.com.br/politicas-de-privacidade/">
              PRIVACIDADE
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://fighthome.com.br/termos-de-uso-e-servicos/">
              TERMOS
            </a>
          </li>

          <li className={clsx(styles.copyright, 'text-sm')}>{environment.copyright}</li>
        </ul>
      </div>
    </ThemeProvider>
  );
}

export default FooterLayout1;
