import { firestore } from 'services';
import { map, shareReplay } from 'rxjs/operators';
import { collectionData } from 'utils/rxfire';
import store from 'store';
import { combineLatest, Observable } from 'rxjs';
import EnrollmentFacade, { Enrollment, getStatusScore, parseCourseEnrollment } from './EnrollmentFacade';
import firebase from 'firebase/app';
import { Picture } from './PictureFacade';
import { User } from './AuthFacade';
import { setCourses } from 'store/actions';
import { Banner } from './BannerFacade';

export type ChildrenType = 'module' | 'video';

export type AccessControlType =
  | 'default'
  | 'free'
  | 'scheduled'
  | 'daysAfterPurchase'
  | 'hidden'
  | 'blocked'
  | 'comingSoon';

export interface AccessControl {
  type: AccessControlType;
  openingDate?: firebase.firestore.Timestamp | null;
  closingDate?: firebase.firestore.Timestamp | null;
  daysAfterPurchase?: number;
}

export interface Course {
  id: string;
  position: number;
  slug: string;
  title: string;
  subtitle: string;
  description: string;
  howWorks: string;
  aboutAuthor: string;
  videoApresentationLink: string;

  tags: string[];

  author: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };

  locked: boolean;
  archived: boolean;
  packages: string[];
  blockTitle: string;
  blockMessage: string;
  blockButtons: CourseBlockButton[];
  banners: Banner[];
  childrenType: ChildrenType;
  layoutPosition: 'top' | 'middle' | 'bottom';

  logo: string;
  thumbnail: string;
  pictures: Picture[];

  coverSM: string;
  coverMD: string;
  coverXL: string;

  accessControl?: AccessControl;

  preferences: Record<string, any>;

  theme: string;
  settings: {
    moduleAspectRatio: number;
    lessonAspectRatio: number;
  };

  isLaunch: boolean;
  isComingSoon: boolean;
  showCover: boolean;

  createdAt: number | firebase.firestore.FieldValue;
  updatedAt: number | firebase.firestore.FieldValue;
  archivedAt: number | firebase.firestore.FieldValue;

  enrollment: Enrollment;
  isEnrolled: boolean;
  isActive: boolean;
}

export interface CourseBlockButton {
  title: string;
  link: string;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
}

export function isCourse(item: any): item is Course {
  return item && item.childrenType !== undefined && item.parentId === undefined;
}

class CourseFacade {
  private collection = firestore.collection('courses');

  private courses$: Observable<Course[]> = collectionData<Course>(this.collection, 'id').pipe(
    map(list => list.filter(item => item.archived === false)),
    map(list => list.sort((a, b) => a.position - b.position)),
    shareReplay({ bufferSize: 1, refCount: false })
  );

  private coursesEnrollments$(user: User) {
    const enrollments$ = EnrollmentFacade.getAllWithUserId$(user.id);
    return enrollments$.pipe(
      map(enrollments => {
        const coursesEnrollments: Record<string, Enrollment> = {};

        for (const enrollment of enrollments) {
          for (const course of enrollment?.class?.courses) {
            const oldCourseEnrollment = coursesEnrollments[course.id];

            if (
              !oldCourseEnrollment ||
              getStatusScore(oldCourseEnrollment.status) < getStatusScore(enrollment.status)
            ) {
              coursesEnrollments[course.id] = enrollment;
            }
          }
        }

        return coursesEnrollments;
      })
    );
  }

  getCourses$(user: User) {
    return combineLatest([this.courses$, this.coursesEnrollments$(user)]).pipe(
      map(([courses, coursesEnrollments]) => {
        for (const course of courses) {
          parseCourseEnrollment(coursesEnrollments[course.id], course);
        }

        /* return courses.filter(
          course => !(course.accessControl?.type === 'hidden' && !course.isEnrolled && user.ll !== 'moderator')
        ); */
        return courses.filter(course => !(course.accessControl?.type === 'hidden'));
      })
    );
  }

  loadCourses(user: User) {
    return this.getCourses$(user).subscribe(courses => {
      store.dispatch(
        setCourses(
          courses,
          courses.filter(course => course.isEnrolled)
        )
      );
    });
  }

  getCourseById$(user: User, id: string) {
    return this.getCourses$(user).pipe(map(list => (id && list.find(it => it.id === id)) || null));
  }

  getCourse$(user: User, slug: string) {
    return this.getCourses$(user).pipe(map(list => (slug && list.find(it => it.slug === slug)) || null));
  }
}

const instance = new CourseFacade();

export default instance;
