export class Permission {
  constructor(public id: string, public title: string, public subpermissions: Permission[] = []) {}
}

export const WildcardPermission = new Permission('*', 'Acesso total');

export const UserManagementPermission = new Permission('memberfy.users.update', 'Gestão de usuários');
export const UserDeletionPermission = new Permission('memberfy.users.delete', 'Excluir usuários');
export const UserPermissionManagementPermission = new Permission(
  'memberfy.users.permissions',
  'Gestão de permissões de usuários'
);
export const ContentManagementPermission = new Permission('memberfy.contents', 'Gestão de conteúdo');
export const ClassroomManagementPermission = new Permission('memberfy.classrooms', 'Gestão de turmas');
export const InvitationManagementPermission = new Permission('memberfy.invitations', 'Gestão de convites');
export const DesignManagementPermission = new Permission('memberfy.design', 'Gestão de design');
export const DeliveryIntegrationManagementPermission = new Permission(
  'memberfy.tools.deliveryIntegrations',
  'Gestão de integrações de entrega de conteúdo'
);
export const CartAbandonmentManagementPermission = new Permission(
  'memberfy.tools.cartAbandonment',
  'Gestão de abandono de carringo'
);
export const CrashlyticsManagementPermission = new Permission(
  'memberfy.tools.crashlytics',
  'Gestão de relatórios de erros'
);
export const AnalyticsManagementPermission = new Permission('memberfy.tools.analytics', 'Gestão de relatórios');

export const OwnerRole = new Permission('roles.owner', 'Proprietário', [WildcardPermission]);
export const AdminRole = new Permission('roles.admin', 'Administrador', [WildcardPermission]);
export const DeveloperRole = new Permission('roles.developer', 'Desenvolvedor', [WildcardPermission]);
export const ProgrammerRole = new Permission('roles.programmer', 'Programador', [WildcardPermission]);
export const ManagerRole = new Permission('roles.manager', 'Gestor', [WildcardPermission]);
export const DesignerRole = new Permission('roles.designer', 'Designer', [
  ContentManagementPermission,
  DesignManagementPermission,
]);
export const EditorRole = new Permission('roles.editor', 'Editor de Vídeos', [ContentManagementPermission]);
export const SupportRole = new Permission('roles.support', 'Suporte', [UserManagementPermission]);
export const AssistantRole = new Permission('roles.assistant', 'Assistente', [
  UserManagementPermission,
  ContentManagementPermission,
  ClassroomManagementPermission,
  InvitationManagementPermission,
  DesignManagementPermission,
  DeliveryIntegrationManagementPermission,
  CartAbandonmentManagementPermission,
]);
export const SellerRole = new Permission('roles.seller', 'Vendedor', [
  UserManagementPermission,
  CartAbandonmentManagementPermission,
]);
export const MemberRole = new Permission('roles.member', 'Aluno');

export const AllPermissions = [
  UserManagementPermission,
  UserDeletionPermission,
  UserPermissionManagementPermission,
  ContentManagementPermission,
  ClassroomManagementPermission,
  InvitationManagementPermission,
  DesignManagementPermission,
  DeliveryIntegrationManagementPermission,
  CartAbandonmentManagementPermission,
  CrashlyticsManagementPermission,
  AnalyticsManagementPermission,
];

export const AllRoles = [
  OwnerRole,
  AdminRole,
  DeveloperRole,
  ProgrammerRole,
  ManagerRole,
  DesignerRole,
  EditorRole,
  SupportRole,
  AssistantRole,
  SellerRole,
  MemberRole,
];

export const AllRolesAndPermissions = [...AllRoles, ...AllPermissions];

export function hasDashboardPermission(permissions: Permission[]) {
  if (!permissions) return false;
  if (permissions.length === 0) return false;

  return permissions.some(permission => permission.id !== MemberRole.id);
}

export function parsePermissions(permissions: Record<string, boolean>): Permission[] {
  const list: Permission[] = [];

  list.push(MemberRole);

  for (const id in permissions || {}) {
    for (const y of AllRolesAndPermissions) {
      if (y.id === id) {
        list.push(y);
        break;
      }
    }
  }

  return list;
}
export function stringfyPermissions(permissions: Permission[]): Record<string, boolean> {
  const obj = {} as Record<string, boolean>;
  for (const x of permissions) {
    obj[x.id] = true;
  }
  return obj;
}

export function hasPermission(hasPermission?: Permission[] | null, permissions?: Permission[] | null) {
  if (!hasPermission) return true;

  if (hasPermission.length === 0) return !permissions;

  if (!permissions || !permissions.length) return false;

  const wildcardId = WildcardPermission.id;

  for (const x of hasPermission) {
    for (const y of permissions) {
      if (x.id === y.id || y.id === wildcardId) return true;
      for (const z of y.subpermissions) {
        if (x.id === z.id || z.id === wildcardId) return true;
      }
    }
  }

  return false;
}
