import firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
function _fromRef(
  ref: any,
  options: firebase.firestore.SnapshotListenOptions | undefined
): Observable<any> {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  return new Observable((subscriber) => {
    const unsubscribe = ref.onSnapshot(options || {}, subscriber);
    return { unsubscribe };
  });
}

export function fromRef(
  ref: firebase.firestore.DocumentReference | firebase.firestore.Query,
  options?: firebase.firestore.SnapshotListenOptions
): Observable<{}> {
  return _fromRef(ref, options);
}

export function fromDocRef(
  ref: firebase.firestore.DocumentReference,
  options?: firebase.firestore.SnapshotListenOptions
): Observable<firebase.firestore.DocumentSnapshot> {
  return fromRef(ref, options) as Observable<firebase.firestore.DocumentSnapshot>;
}

export function fromCollectionRef(
  ref: firebase.firestore.Query,
  options?: firebase.firestore.SnapshotListenOptions
): Observable<firebase.firestore.QuerySnapshot> {
  return fromRef(ref, options) as Observable<firebase.firestore.QuerySnapshot>;
}

export function doc(
  ref: firebase.firestore.DocumentReference
): Observable<firebase.firestore.DocumentSnapshot> {
  return fromDocRef(ref);
}

/**
 * Returns a stream of a document, mapped to its data payload and optionally the document ID
 * @param query
 */
export function docData<T>(
  ref: firebase.firestore.DocumentReference,
  idField?: string
): Observable<T | null> {
  return doc(ref).pipe(map((snap) => (snap.exists ? (snapToData(snap, idField) as T) : null)));
}

// export function snapToData(
//   snapshot: firebase.firestore.DocumentSnapshot,
//   idField?: string
// ): {} | null {
//   return snapshot.exists
//     ? {
//         ...snapshot.data(),
//         ...(idField ? { [idField]: snapshot.id } : null),
//       }
//     : null;
// }

/**
 * Return a stream of document snapshots on a query. These results are in sort order.
 * @param query
 */
export function collection(
  query: firebase.firestore.Query
): Observable<firebase.firestore.QueryDocumentSnapshot[]> {
  return fromCollectionRef(query).pipe(map((changes) => changes.docs));
}

/**
 * Returns a stream of documents mapped to their data payload, and optionally the document ID
 * @param query
 */
export function collectionData<T>(
  query: firebase.firestore.Query,
  idField?: string
): Observable<T[]> {
  return collection(query).pipe(
    map((arr) => {
      return arr.filter((snap) => snap.exists).map((snap) => snapToData(snap, idField) as T);
    })
  );
}

export function snapToData<T>(
  snapshot: firebase.firestore.DocumentSnapshot,
  idField: string = 'id'
): T | null {
  if (snapshot.exists) {
    const data = snapshot.data() as any;
    data[idField] = snapshot.id as keyof typeof data;
    return data as T;
  }
  return null;
}

export function collectionToData<T>(
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): T[] {
  return snapshot.docs.map((it) => snapToData(it)).filter((s) => !!s) as T[];
}
