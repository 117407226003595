import { RouteConfig } from 'configs';
import React from 'react';

const RegisterConfig: RouteConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    {
      path: '/cadastrar',
      component: React.lazy(() => import('./Register')),
      exact: true,
    },
  ],
};

export default RegisterConfig;
