import { firestore } from 'services';

import firebase from 'firebase/app';

export interface UserLogin {
  id: string;
  loggedAt: firebase.firestore.Timestamp;
}

class AuthLoginFacade {
  create(userId: string) {
    return firestore.collection(`users/${userId}/logins`).add({
      loggedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
}

const instance = new AuthLoginFacade();

export default instance;
