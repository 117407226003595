import firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { firestore } from 'services/firebase';
import { collectionData } from 'utils/rxfire';

export interface Comment {
  id: string;
  parentId: string;
  message: string;

  user: {
    id: string;
    name: string;
    avatarURL: string | null;
  };

  content: {
    id: string;
    title: string;
    thumbnail: string | null;
    type: 'video';
  };

  commentIn: 'video' | 'comment';

  answered: boolean;
  moderation: boolean;
  updated: boolean;
  pinned: boolean;

  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export const PAGE_SIZE = 10;

class CommentFacade {
  collection = firestore.collection('comments');

  getAllByContentId$(contentId: string) {
    return collectionData<Comment>(this.collection.where('content.id', '==', contentId), 'id').pipe(
      map(ls => ls.sort((a, b) => (b.updatedAt?.seconds || 0) - (a.updatedAt?.seconds || 0)))
    );
  }

  create(data: Partial<Comment>) {
    return this.collection.add({
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  update(id: string, data: Partial<Comment>) {
    return this.collection.doc(id).update({
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    });
  }

  remove(id: string) {
    return this.collection.doc(id).delete();
  }
}

const instance = new CommentFacade();

export default instance;
