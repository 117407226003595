import LayoutConfig from 'layouts/layout1/LayoutConfig';

const settingsConfig = {
  layout: {
    style: 'layout1',
    config: LayoutConfig.defaults,
  },
  customScrollbars: true,
  theme: {
    main: 'default',
    navbar: 'default',
    toolbar: 'defaultToolbar',
    footer: 'default',
  },
};

export default settingsConfig;
