import * as Actions from '../../actions/fuse/index';
import navigationConfig from 'configs/navigationConfig';
import { Reducer } from 'redux';
import { MyAction } from 'store';
import { Navigation } from 'facades/NavigationFacade';

type NavigationState = Navigation[];

const initialState: NavigationState = navigationConfig;

const navigation: Reducer<NavigationState, MyAction> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_NAVIGATION: {
      return [...action.payload];
    }
    case Actions.RESET_NAVIGATION: {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};

export default navigation;
