import { collectionData, docData } from 'utils/rxfire';
import { firestore } from 'services';
import { combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CompleteFacade, FavoriteFacade } from '.';
import firebase from 'firebase/app';
import { Picture } from './PictureFacade';

export interface Video {
  id: string;
  parentId: string;
  position: number;
  slug: string;
  title: string;

  provider: 'vimeo' | 'youtube';
  vimeo: number;
  youtube: number;

  audio: string;
  thumbnail: string;
  pictures: Picture[];

  duration: number;
  archived: boolean;
  attachments: VideoAttachment[];
  accessControl: VideoAccessControl;

  favorite: boolean;
  complete: boolean;
  selected: boolean;
}

export interface VideoAccessControl {
  type: string;
  openingDate: firebase.firestore.Timestamp | null;
  closingDate: firebase.firestore.Timestamp | null;
  daysAfterPurchase: number;
}

export interface VideoStory {
  title: string;
  text: string;
  audio: string;
}

export interface VideoAttachment {
  title: string;
  url: string;
  file: {
    name: string;
    size: number;
    type: string;
  };
}

export function isVideo(item: any): item is Video {
  return item && item.childrenType === undefined && item.parentId !== undefined;
}

class VideoFacade {
  collection = firestore.collection('videos');

  getVideos$(parentId: string) {
    return collectionData<Video>(
      this.collection.where('parentId', '==', parentId).where('archived', '==', false),
      'id'
    ).pipe(
      map(list => list.sort((a, b) => a.position - b.position)),
      switchMap(videos => {
        const videos$ = videos.map(video => {
          return combineLatest([
            FavoriteFacade.contains$(video),
            CompleteFacade.contains$(video),
          ]).pipe(map(([favorite, complete]) => ({ ...video, favorite, complete } as Video)));
        });

        return combineLatest(videos$).pipe(startWith(videos));
      })
    );
  }

  getVideoById$(id: string) {
    return docData<Video>(this.collection.doc(id), 'id');
  }

  getVideo$(videoSlug: string, parentId: string) {
    return this.getVideos$(parentId).pipe(
      map(list => list.find(it => it.slug === videoSlug) || null)
    );
  }
}

const instance = new VideoFacade();

export default instance;
