export const HIDE_MESSAGE = '[MESSAGE] CLOSE';
export const SHOW_MESSAGE = '[MESSAGE] SHOW';

interface Options {
  anchorOrigin?: {
    vertical: 'bottom' | 'top';
    horizontal: 'left' | 'center' | 'right';
  };
  autoHideDuration?: number | null;
  message: string;
  variant?: 'success' | 'warning' | 'error' | 'info';
  action?: React.ReactNode;
}

export function hideMessage() {
  return {
    type: HIDE_MESSAGE,
  };
}

export function showMessage(options: Options) {
  return {
    type: SHOW_MESSAGE,
    options,
  };
}
