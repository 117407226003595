import { Redirect } from 'react-router-dom';
import FuseUtils from 'utils/FuseUtils';
import { RouteConfig as MyRouteConfig } from './RouteConfig';
import LoginConfig from 'pages/login/LoginConfig';
import ForgotPasswordConfig from 'pages/forgot-password/ForgotPasswordConfig';
import RegisterConfig from 'pages/register/RegisterConfig';
import CoursesConfig from 'pages/courses/CoursesConfig';
import CourseConfig from 'pages/course/CourseConfig';
import TalkToUsConfig from 'pages/talk-to-us/TalkToUsConfig';
import ProfileConfig from 'pages/profile/ProfileConfig';
import { RouteConfig } from 'react-router-config';
import InvitationConfig from 'pages/invitation/InvitationConfig';
import CoursesNutritionConfig from 'pages/courses-nutrition/CoursesNutritionConfig';
import CoursesTrainingConfig from 'pages/courses-training/CoursesTrainingConfig';

const routeConfigs: MyRouteConfig[] = [
  LoginConfig,
  ForgotPasswordConfig,
  RegisterConfig,
  CoursesConfig,
  CoursesTrainingConfig,
  CoursesNutritionConfig,
  CourseConfig,
  TalkToUsConfig,
  ProfileConfig,
  InvitationConfig,
];

const routes: RouteConfig[] = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/courses" />,
  },
  {
    component: () => <h1>Not Found</h1>,
  },
];

export default routes;
