import { Reducer } from 'redux';
import { MyAction } from 'store';
import {
  SET_OPEN_PROFILE_DIALOG,
  SET_LOADING_PROFILE_DIALOG,
} from '../actions/profile-dialog.actions';

type ProfileDialogState = {
  open: boolean;
  loading: boolean;
};

const initialState: ProfileDialogState = {
  open: false,
  loading: false,
};

const profileDialog: Reducer<ProfileDialogState, MyAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_PROFILE_DIALOG: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case SET_LOADING_PROFILE_DIALOG: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default profileDialog;
