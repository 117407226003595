import React from 'react';
import { User } from 'facades/AuthFacade';
import { useHistory } from 'react-router-dom';
import { auth } from 'services';
import useLoad from 'hooks/useLoad';
import { AuthFacade } from 'facades';
import { Permission } from 'facades/PermissionFacade';

const AuthenticationContext = React.createContext<User | null | undefined>(undefined);

const PermissionsContext = React.createContext<Permission[] | null | undefined>(undefined);

function AuthenticationProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>();
  const [permissions, setPermissions] = React.useState<Permission[] | null>();

  useLoad(() => AuthFacade.getUser$().subscribe(setUser));
  useLoad(() => AuthFacade.getPermissions$().subscribe(setPermissions));

  return (
    <AuthenticationContext.Provider value={user}>
      <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const user = React.useContext(AuthenticationContext);

  const loading = user === undefined;

  return { user, loading };
}

function usePermissions() {
  const permissions = React.useContext(PermissionsContext);

  const loading = permissions === undefined;

  return { permissions, loading };
}

function useLogout() {
  const history = useHistory();

  const logout = () => {
    history.push({
      pathname: '/',
    });

    auth.signOut();
  };

  return { logout };
}

export { AuthenticationProvider, useAuthentication, useLogout, usePermissions };
