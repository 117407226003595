import React from 'react';
import routes from 'configs/routesConfig';
import { RouteConfig } from 'react-router-config';

type Dispatch = React.Dispatch<React.SetStateAction<RouteConfig[]>>;

type Props = { children: React.ReactNode };

const RouteStateContext = React.createContext<RouteConfig[]>([]);
const RouteDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function useRouteState() {
  return React.useContext(RouteStateContext);
}

function useRouteDispatch() {
  const context = React.useContext(RouteDispatchContext);

  if (!context) {
    throw new Error(`useRouteDispatch must be used within a RouteProvider`);
  }

  return context;
}

function RouteProvider({ children }: Props) {
  const [route, setRoute] = React.useState<RouteConfig[]>(routes);

  return (
    <RouteStateContext.Provider value={route}>
      <RouteDispatchContext.Provider value={setRoute}>{children}</RouteDispatchContext.Provider>
    </RouteStateContext.Provider>
  );
}

export { RouteProvider, useRouteState, useRouteDispatch };
