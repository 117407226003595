import { Navigation } from 'facades/NavigationFacade';

const navigationConfig: Navigation[] = [
  {
    id: 'user-interface',
    title: '',
    type: 'group',
    icon: 'web',
    children: [
      {
        id: 'courses',
        title: 'Início',
        type: 'item',
        icon: 'school',
        url: '/courses',
      },
      {
        id: 'nutrition',
        title: 'Nutricionais',
        type: 'item',
        icon: 'school',
        url: '/nutrition',
      },
      {
        id: 'training',
        title: 'Treinamentos',
        type: 'item',
        icon: 'school',
        url: '/training',
      },
      {
        id: 'talk-to-us',
        title: 'Fale com a Gente',
        type: 'item',
        icon: 'info',
        url: '/talk-to-us',
      },
    ],
  },
];

export default navigationConfig;
