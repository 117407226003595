import _ from '@lodash';

export interface Navigation {
  id: string;
  title: string;
  type: string;
  url?: string;
  target?: string;
  icon?: string;
  exact?: boolean;
  badge?: {
    title?: string | number;
    bg?: string;
    fg?: string;
  };
  children?: Navigation[];
}

class NavigationFacade {
  public updateNavItem(nav: Navigation[], id: string, item: Navigation): Navigation[] {
    return nav.map(_item => {
      if (_item.id === id) {
        return _.merge({}, _item, item);
      }
      if (_item.children) {
        return _.merge({}, _item, {
          children: this.updateNavItem(_item.children, id, item),
        });
      } else {
        return _.merge({}, _item);
      }
    });
  }

  public removeNavItem(nav: Navigation[], id: string): Navigation[] {
    return nav
      .map(_item => {
        if (_item.id === id) {
          return null;
        }
        if (_item.children) {
          return _.merge({}, _.omit(_item, ['children']), {
            children: this.removeNavItem(_item.children, id),
          });
        } else {
          return _.merge({}, _item);
        }
      })
      .filter(s => s) as Navigation[];
  }

  public prependNavItem(nav: Navigation[], item: Navigation, parentId: string): Navigation[] {
    if (!parentId) {
      return [item, ...nav];
    }

    return nav.map(_item => {
      if (_item.id === parentId && _item.children) {
        return {
          ..._item,
          children: [item, ..._item.children],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.prependNavItem(_item.children, item, parentId),
        });
      } else {
        return _.merge({}, _item);
      }
    });
  }

  public appendNavItem(nav: Navigation[], item: Navigation, parentId: string): Navigation[] {
    if (!parentId) {
      return [...nav, item];
    }

    return nav.map(_item => {
      if (_item.id === parentId && _item.children) {
        return {
          ..._item,
          children: [..._item.children, item],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.appendNavItem(_item.children, item, parentId),
        });
      } else {
        return _.merge({}, _item);
      }
    });
  }
}

const instance = new NavigationFacade();

export default instance;
